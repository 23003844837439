import 'owl.carousel';
import moment from 'moment';
jQuery( document ).ready( function ( $ ) {
	// Music
	const music = $( '#music .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );
	// Videos
	const videos = $( '#videos .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		video: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );
	// Shop
	const shop = $( '#shop .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		video: true,
		dots: false,
		nav: true,
		autoHeight: true,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );
	// Nav
	$( 'nav>ul>li>a.local' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );
		const target = href.replace( '#', '' );
		$( 'main>section' ).fadeOut( 400, function () {
			setTimeout( function () {
				music.trigger( 'refresh.owl.carousel' );
				videos.trigger( 'refresh.owl.carousel' );
				shop.trigger( 'refresh.owl.carousel' );
				$( 'main>section' + href ).fadeIn( 400 );
			}, 500 );
		} );
		/*$( '#bgs>.bg' ).fadeOut( 400, function () {
			$( '#bgs>.bg.' + target ).fadeIn( 400 );
		} );*/
	} );
	// Tour
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/j.Howell/events?app_id=45PRESS_j.howell',
    method: 'GET',
    dataType: 'json',
    xhrFields: {
        withCredentials: false
    },
    error: () => {
    },
    success: data => {
        const events = $( '#events' );
        let html = '';
        if ( data.length ) {
            for ( let event of data ) {
							console.log(event)
                const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                html += '<div class="event" data-bit-id="' + event.id + '">';
                html += '<div class="event-date">';
                html += '<span class="event-date-month">' + moment( event.datetime ).format( 'MMM' ) + '</span>';
                html += '<span class="event-date-day">' + moment( event.datetime ).format( 'DD' ) + '</span>';
                html += '</div>';
                html += '<div class="event-info">';
                html += '<div class="event-venue">' + event.venue.name + '</div>';
                html += '<div class="event-location">' + event_location + '</div>';
                html += '</div>';
                html += '<a href="' + event.url + '" target="_blank" class="btn btn-secondary">Tickets</a>';
                html += '</div>';
            }
            events.html( html );
        } else {
            events.html( 'No upcoming events.' );
        }
    }
  });
} );
